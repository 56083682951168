<template>
  <div class="modal is-large is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Nueva dirección</p>
        <button class="delete" aria-label="close" type="reset" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="field">
              <label class="label" :class="{'has-text-danger': isError}" >Nombre</label>
              <div class="control">
                <input
                  type="text"
                  class="input"
                  v-model="name"
                  :class="{ 'is-danger': vverrors.first('name') }"
                  v-validate="'required'"
                >
              </div>
            </div>
          </div>
          <div class="column is-12">
            <div class="field">
              <label class="label" :class="{'has-text-danger': isError}" >Dirección</label>
              <div>
                <vue-google-autocomplete
                  id="map"
                  classname="input"
                  placeholder=""
                  v-on:placechanged="getAddressData"
                  v-on:inputChange="onChangeAddress"
                >
                </vue-google-autocomplete>
              </div>
            </div>
            <template v-if="addressNewLocation">
              <product-ubication
                :lat="addressNewLocation.latitude"
                :lng="addressNewLocation.longitude"
              />
            </template>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="field is-grouped">
            <div class="control">
              <button class="button is-link is-light" @click="close()">Cancelar</button>
            </div>
            <div class="control">
              <button class="button button-givu" @click="onSaveCharacteristic()">Guardar</button>
            </div>
          </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Function
    },
    saveAddress: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    VueGoogleAutocomplete: () => import('vue-google-autocomplete'),
    ProductUbication: () => import('@/components/Products/ProductUbication')
  },
  data () {
    return {
      name: '',
      addressNewLocation: null,
      isError: false
    }
  },
  mounted () {
    this.characteristicsSelectedTemporally = this.characteristicsSelected
  },
  methods: {
    getAddressData (addressData, placeResultData, id) {
      this.addressNewLocation = {
        ...addressData,
        latitude: addressData.latitude,
        longitude: addressData.longitude,
        apartmentNumber: addressData.apartmentNumber || null,
        city: addressData.locality,
        deputation: placeResultData.vicinity,
        neighborhood: addressData.route,
        street: addressData.street || null,
        street_number: addressData.street_number || null,
        zip_code: addressData.postal_code || null
      }
    },
    onChangeAddress (address) {
      if (!address.newVal && address.oldVal) this.addressNewLocation = null
    },
    async onSaveCharacteristic () {
      if (await this.$validator.validateAll()) {
        if (this.addressNewLocation) {
          let dataAddress = {
            ...this.addressNewLocation,
            name: this.name
          }
          return this.saveAddress(dataAddress)
        }
      }
      this.isError = true
    }
  }
}
</script>
